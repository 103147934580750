<template>
    <div> 
        <IconTopTable />
        <div class="content-table-results mt-2">
            <table class="table table-fixed table-stripes" id="table_Ema">
                <ColumnColGroup page="result" :column="data.column" :default_column="data.default_column" />
                <ColumnThead page="result" :column="data.column" :default_column="data.default_column" />
                <tbody> 
                    <template v-if="data.data.data && data.data.data.length > 0">
                        <tr v-for="(item, key) in data.data.data" :key="key">                
                            <td>
                                <label class="table-check">
                                    <input type="checkbox" v-model="select_export" :value="item.drug_id">
                                    <span class="checkbox-table"></span>
                                </label>
                            </td>
                            <td>
                                <router-link :to="'/detail/'+item.drug_id+'/'+item.agency_model.toLowerCase()" target="_blank" rel="noopener">
                                    <div class="icon-fiche"></div>
                                </router-link>
                                <div v-if="isAdmin()">
                                     <a :href="modifyPage(item.agency_model.toLowerCase(), item.drug_id)" target="_blank" rel="noopener"> 
                                        <em class="icon-edit"></em>
                                    </a>
                                </div>
                            </td>
                            <template v-for="(column) in data.column" :key="column.code">
                                <!-- <td v-if="column.Status && column.code == 'ema_001'" :code="column.code">
                                    <span v-html="icon_link(item.drug_id, 'ema')"></span>
                                </td> -->
                                <td v-if="column.Status && column.code == 'ema_002'" :code="column.code">
                                    {{item.drug_id}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_003'" :code="column.code">
                                    {{check_empty(item.category)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_004'" :code="column.code">
                                    {{check_empty(item.dci_new)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_027'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item.current_ma_status))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_005'" :code="column.code">
                                    <span v-html="check_empty(overview_regulatory_status(item))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_006'" :code="column.code">
                                    {{check_empty(item.name)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_007'" :code="column.code">
                                    {{check_empty(item.dci)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_008'" :code="column.code">
                                    {{check_empty(item.firm)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_009'" :code="column.code">
                                    {{ check_empty(DDMMMYY(item.ema.date_amm))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_010'" :code="column.code">
                                    {{ check_empty(DDMMMYY(item.ema.date_epar))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_011'" :code="column.code">
                                    {{check_empty(DDMMMYY(item.decision_date_num))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_012'" :code="column.code">                                
                                    {{check_empty_with_locale(item.decision,'decision.')}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_013'" :code="column.code">
                                    <div v-html="ema_proccess(item.ema)"></div>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_014'" :code="column.code">
                                    <div v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.type_of_ma_fr))"></div>
                                    <div v-else v-html="check_empty(array(item.type_of_ma_en))"></div>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_015'" :code="column.code">
                                    <span v-html="check_empty(treatment_line(item.treatment_line))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_016'" :code="column.code">
                                    {{check_empty(item.specificity)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_017'" :code="column.code">
                                    {{check_empty(item.administration)}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_018'" :code="column.code">
                                    <span v-html="check_empty(array(item.atc))"> </span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_019'" :code="column.code">                                
                                    <span v-html="check_empty(array(item['indexation_'+$i18n.locale]))"></span>
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'ema_020'" :code="column.code">
                                    <Readmore :longText="item['indication_'+$i18n.locale]" />
                                </td>                            
                                <td v-else-if="column.Status && column.code == 'ema_021'" :code="column.code">
                                    <span v-if="$i18n.locale === 'fr'" v-html="check_empty(array(item.classification_fr))"></span>
                                    <span v-else v-html="check_empty(array(item.classification_en))"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_022'" :code="column.code">
                                    {{$t(check_empty(item.mk_orphelin))}}
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_023'" :code="column.code">
                                    <Readmore :longText="item.ema['comment_'+$i18n.locale]" />
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_024'" :code="column.code" >
                                    <Readmore :longText="icon_link_ema(item.links)" textAlign="center"/>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_025'" :code="column.code">
                                    <span v-html="nct_number(item.nct_numbers)" @click="drug_id = item.drug_id"></span>
                                </td>
                                <td v-else-if="column.Status && column.code == 'ema_026'" :code="column.code">
                                    {{check_empty(item.guidance_id)}}
                                </td>

                            </template>
                        </tr>

                    </template>

                    <template v-else><tr><td colspan="99">{{$t('No Data')}}</td></tr></template>

                </tbody>

            </table>
        </div>
        <ModalEssaisClinique :id="drug_id" agency="Ema"/>
    </div>
</template>

<script>

import IconTopTable from '@/components/result/icon_top_table.vue';
import ColumnColGroup from '@/components/elements/column_colgroup.vue';
import ColumnThead from '@/components/elements/column_thead.vue';
import Readmore from '@/components/elements/readmore.vue';
import { isAdmin, overview_regulatory_status, modifyPage, icon_link, DDMMMYY, check_empty, 
        nct_number, treatment_line, array, check_empty_with_locale, link_detail_url } from '@/utils'
import ModalEssaisClinique from '@/components/elements/modal_essais_clinique.vue'
export default {
    name: "Ema",
    components: {
        IconTopTable,
        ColumnColGroup,
        ColumnThead,
        Readmore,
        ModalEssaisClinique
    },
    data(){
        return {
            drug_id : 0 //for essais clinique popup
        }
    },
     computed: {                
        data(){
            return this.$store.getters['result/data'];
        },
        listAgency(){
            return this.$store.getters['result/listAgency'];
        },
        AgencyLinkObj(){
            let AgencyLinkObj = {}
            for (let agency in this.listAgency){
                AgencyLinkObj[this.listAgency[agency].agency_model] = {
                    agency : this.listAgency[agency].acronym,
                    country : this.listAgency[agency].alpha_2_code
                }
            }
            return AgencyLinkObj
        },
        select_export: {
            set(val){ this.$store.dispatch("result/select_export", val) },
            get(){ 
                if(this.$store.getters['result/select_export_checkAll']){
                    return true
                }else{
                    return this.$store.getters['result/select_export'] ? this.$store.getters['result/select_export'].listID : null 
                }
            }
        }
    },
    methods: {
        overview_regulatory_status,
        isAdmin,
        modifyPage,        
        icon_link,
        DDMMMYY,
        check_empty,
        nct_number,
        treatment_line,
        array,
        check_empty_with_locale,
        getResults(page = 1) {
            console.log(page)
        },
        ema_proccess(param){
            const ema_proccess = [
                'check_accelerated_assessment', 
                'check_prime'
            ]
            let txt = ''
            for (const item of ema_proccess){
                if(param[item]){
                    txt = txt + `<div>${this.$t(item)}</div>`
                }
            }
            txt = this.check_empty(txt)
            return txt
        },
        icon_link_ema : function (param){
            let html = ''
            if(param){
                for(let val in param){
                    let link = param[val]
                    if(val.toLowerCase() != 'healthcanada' && val.toLowerCase() != 'economicevaluation'){
                        for(const id in link){
                            if(val === 'Fr') val = 'Transparence'
                            if(this.AgencyLinkObj[val]){
                                html += `<a href="/detail/${link[id]}/${link_detail_url(val)}" target="_blank" rel="noopener">
                                        ${this.AgencyLinkObj[val].agency} [${this.AgencyLinkObj[val].country}]</a> <br><br>`
                            }
                        }
                    }
                }
            }
            return html
        }
    },
}
</script>

<style scoped>

/* CSS for fixed height of table for show in one page */
@media only screen and (max-width: 862px){
    .content-table-results{
        height: auto;
    }
}
@media only screen and (min-width: 863px){
    .content-table-results{
        max-height: 70vh;
    }
}
@media only screen and (min-height: 863px){
    .content-table-results{
        max-height: 65vh;
    }
}
@media only screen and (min-height: 1320px){
    .content-table-results{
        max-height: 75vh;
    }
}

</style>